/* eslint-disable camelcase */
import React, { Component, Suspense, lazy } from 'react';
import { Tab, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { IconContainer, SocialFeedsContainer, ContentContainer, InputFieldContainer, InputImage, HeaderContainer, CommentButton,
  StyledTabContainer, StyledTitle, ImageContainer, ButtonContainer, SideBarContainer, TileContainer, TitleContent, SideBarContent,
  StyledImage, NotificationDescription, FeaturedEventsContainer, NoComments, Layout,
  Profile, NoFriendRequests, NoActivityFeeds, FeedsContainer, CommentSection, ScrollableFeedsContainer, AssessmentContainer,
  TipInformation, ProfileContainer
} from './styles';
import { PageTitle } from '../CreateEvent/styles';

import Waiting from '../Waiting';
import FeaturedChallenges from '../FeaturedChallenges';
import SingleFeed from './SingleFeed';
import Comments from '../Comments';
import WellnessExpertSection from '../WellnessExpertSection';
import Loading from '../Loading';

import { ImageUrl, imageErrorMessage } from '../../utils/constants';
import { resetOrientation, getOrientation, checkImage, applyHighlights, getPermissionStatus} from '../../utils/methods';
import Image from '../Image';

import { getUserProfileImage, addCommentReplyOnPost, addSocialInspiration, getCommentsOnPost, getUsersToShoutout, getSuggestionsList } from '../../redux/actions';

const EditPostPopUp = lazy(() => import('../EditPostPopUp'));
const DeletePostPopUp = lazy(() => import('../DeletePostPopup'));
import SocialFeedTextarea from './SocialFeedTextarea';
import {HraIcon } from '../DailyTip/styles';
import SuggestionForYou from '../SuggestionForYou';
import { withTranslation } from 'react-i18next';

class SocialFeeds extends Component {
  constructor() {
    super();
    this.state = {
      imgSrc: [],
      showModal: '',
      inspirationQuote: '',
      showCommentsFor: '',
      pageNumber: 1,
      showEditDeletePopup: '',
      editDetails: '',
      editAndDelete: false,
      isUploadedImage: false,
      refreshFileReader: false,
      mentionedUsers:[],
      shoutoutType: 0,
      users: [],
      showGivenShoutout: false,
      shoutoutImage: null,
      shoutoutText: null,
      userIdArray: [],
      taggedUser: '',
      postDisabled: true,
      exemplifyID: null
    }
  }

  componentDidMount() {
    const { profileImage, fetchUserProfileImage,getSuggestionsList } = this.props;
    getSuggestionsList();
    if(!profileImage) {
      fetchUserProfileImage();
    }
  }

  openEditAndDelete = () => {
    this.setState({
      editAndDelete: !this.state.editAndDelete
    })
  };

  changeEditDeletePopup = (value, feed) => {
    this.setState({ editDetails: feed }, () => {
      this.setState({ showEditDeletePopup: value });
    });
  };

  onHideEditDeletePopup = () => {
    this.setState({
      showEditDeletePopup: ''
    },() => {
      this.openEditAndDelete();
    })
  };

  onChange = (e) => {
    let file = e.target.files[0];
    if(checkImage(e.target.files)) {
      if((file?.size/1000000) <= 20){
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          this.setState({
            imgSrc: [reader.result],
            showModal: 'postModal',
            refreshFileReader: true
          });
          getOrientation(file, (or) => {
            resetOrientation([reader.result], or, (baseImage) => {
              this.setState({
                imgSrc: [baseImage],
                refreshFileReader: false
              });
            });
          });
        };
      } else {
        toast.error('Please select image file less than 20MB');
        document.getElementById('uploadPhoto') && (document.getElementById('uploadPhoto').value = '');
      }
    } else {
      toast.error(imageErrorMessage);
      document.getElementById('uploadPhoto') && (document.getElementById('uploadPhoto').value = '');
    }
  };

  getCommentsForPost = (id) => {
    const { fetchCommentsOnPost } = this.props;
    this.setState({
      showCommentsFor: id
    });
    fetchCommentsOnPost(id);
  };

  onScroll = (e) => {
    const { pageNumber } = this.state;
    const { count, feeds } = this.props;
    const bottom = Math.round(e.target.scrollHeight - parseInt(e.target.scrollTop,10)) === Math.round(e.target.clientHeight);
    if (bottom && feeds.length < count) {
      this.setState({
        pageNumber: pageNumber + 1
      }, () => {
        this.props.loadMoreFeeds(this.state.pageNumber);
      });
    }
  };

  renderFeeds = (feeds) => {
    const { showCommentsFor, editAndDelete } = this.state;
    const { isLoading, comments, replySuccess, postReplyOnComment, profileImage, history, enableTrigger } = this.props;
    return (
      <div>
        {
          feeds && feeds.length > 0 ?
            <ScrollableFeedsContainer onScroll={this.onScroll}>
              {
                feeds.map((feed, index) => (
                  <FeedsContainer key={index}>
                    <SingleFeed
                      feed={feed}
                      description={feed.description}
                      taggedUsers={feed.shoutout_user_name}
                      getCommentsForPost={this.getCommentsForPost}
                      changeEditDeletePopup={this.changeEditDeletePopup}
                      editAndDelete = {editAndDelete}
                      openEditAndDelete={this.openEditAndDelete}
                      profileImage={profileImage}
                      updationList="response"
                      commentCount={feed.comment_count}
                      history={history}
                      enableTrigger={enableTrigger}
                    />
                    {showCommentsFor === feed.id &&
                    (isLoading ? <Loading isHorizontal /> :
                      (isEmpty(comments) ? <NoComments>No Comments to display</NoComments> :
                        <Comments
                          comments={comments}
                          postReply={postReplyOnComment}
                          replySuccess={replySuccess}
                          id={feed.id}
                          history={history}
                          enableTrigger={enableTrigger}
                        />))}
                  </FeedsContainer>
                ))
              }
            </ScrollableFeedsContainer>: <NoComments>No posts in this section</NoComments>

        }
      </div>
    )
  };

  addInspiration = (e) => {
    this.setState({
      inspirationQuote: e.target.value,
    });
  };

  updateText = (text, userId, taggedUserName, bool) => {
    const { userIdArray, users, taggedUser } = this.state;
    let userIdArr = [...userIdArray];
    let userNameArr = [...users];
    let taggedUsers = taggedUser;
    if(userIdArr.indexOf(userId) === -1) {
      userIdArr.push(userId);
      userNameArr.push(taggedUserName);
      if(!_.isEmpty(taggedUsers))
        taggedUsers = `${taggedUsers}, ${taggedUserName}`;
      else
        taggedUsers = taggedUserName;
    }
    this.setState({
      inspirationQuote: text,
      userIdArray: userIdArr,
      users: userNameArr,
      taggedUser: taggedUsers,
      showGivenShoutout: bool
    });
  };

  updateShoutoutArray = (mentionedUsers, text, image, exemplify_id) => {

    this.setState({
      mentionedUsers: mentionedUsers,
      showGivenShoutout: true,
      shoutoutText: text,
      shoutoutImage: image,
      shoutoutType: exemplify_id ? exemplify_id : null,
      exemplifyID: exemplify_id ? exemplify_id : null
    });
  };

  onRemoveShoutout = (array) => {
    this.setState({
      [array]: [],
      showGivenShoutout: false,
      taggedUser: '',
      shoutoutImage: null,
      shoutoutText: null,
      userIdArray: [],
      users: [],
      shoutoutType: 0,
      mentionedUsers: []
    });
  };

  onRemoveUser = () => {
    this.setState({
      taggedUser: '',
      showGivenShoutout: false,
      userIdArray: [],
      shoutoutImage: null,
      shoutoutText: null,
      users: []
    });
  };

  renderInputField = (placeholder,  UpdateStatus, isCommentOrReply) => (
    <InputFieldContainer UpdateStatus={UpdateStatus}>
      <SocialFeedTextarea
        userCompany={this.props.userCompany}
        addInspiration= {this.addInspiration}
        inspirationQuote={this.state.inspirationQuote}
        updateText={this.updateText}
        users={this.state.users}
        userIdArray={this.state.userIdArray}
        placeholder={placeholder}
        showGivenShoutout={this.state.showGivenShoutout}
        updateShoutoutArray={this.updateShoutoutArray}
        emptyShoutoutArray={this.emptyShoutoutArray}
        onRemoveShoutout={this.onRemoveShoutout}
        isCommentOrReply={isCommentOrReply}
        shoutoutText={this.state.shoutoutText}
        shoutoutImage={this.state.shoutoutImage}
        taggedUser={this.state.taggedUser}
        onRemoveUser={this.onRemoveUser}
        enableTrigger={this.props.enableTrigger}
      />
      { !this.state.refreshFileReader &&
      <IconContainer cameraIcon>
        { UpdateStatus === 'UpdateStatus' ?
          <InputImage htmlFor="uploadPhoto" noMarginTop>
            <i className="fas fa-camera" />
            <input
              id="uploadPhoto"
              type="file"
              name="image"
              accept=".jpeg, .png, .jpg*"
              multiple={false}
              onChange={(e) => this.onChange(e)}
              onClick={(e) => e.target.files[0] && this.onChange(e)}
            />
          </InputImage> : null }
      </IconContainer>
      }
    </InputFieldContainer>
  );

  renderDidYouKnowTip = () => (
    <TileContainer>
      <TitleContent>
        <i className="fas fa-question" />
        <span>&nbsp;Did you know?</span>
      </TitleContent>
      <SideBarContent>
        <span>Did you know that improving your balance can improve your athletic ability?</span>
      </SideBarContent>
    </TileContainer>
  );

  acceptOrRejectRequest = (id, status) => {
    const { acceptOrRejectFriendRequest } = this.props;
    acceptOrRejectFriendRequest({request_id: id, status: status});
  };

  renderFriendRequestModal = () => {
    const { showModal } = this.state;
    const { friendRequests } = this.props;
    return (
      <Modal show={showModal === 'requestsModal'} onHide={this.onHide}>
        <Modal.Header closeButton>
          <StyledTitle>Friend Requests</StyledTitle>
        </Modal.Header>
        <Modal.Body>
          {
            friendRequests ? friendRequests.map((request, index) => (
              <ProfileContainer key={index}>
                <Profile>
                  <div className="flex">
                    <Image src={`${ImageUrl}/${request.profile_image}`} alt={request.fullname} />
                    <span>{request.fullname}</span>
                  </div>
                </Profile>
                <Profile>
                  <button onClick={() => this.acceptOrRejectRequest(request.id, 'Accepted')}>accept</button>
                  <button onClick={() => this.acceptOrRejectRequest(request.id, 'Rejected')}>delete</button>
                </Profile>
              </ProfileContainer>
            )) :
              <NoFriendRequests>No friend requests to display</NoFriendRequests>
          }
        </Modal.Body>
      </Modal>
    )
  };

  showModal = () => {
    this.setState({
      showModal: 'requestsModal'
    })
  };

  renderFriendRequests = (requests) => (
    <TileContainer>
      <TitleContent isClickable onClick={() => this.showModal()}>
        <i className="fas fa-user" />
        <span>Friend Requests:</span>
      </TitleContent>
      {
        requests.length > 0 ?
          <SideBarContent isClickable>
            {requests.map((request, index) => (
              <div key={index} className="image-container" onClick={() => this.showModal()}>
                <Image
                  image={request.profile_image}
                  alt={request.fullname}
                />
              </div>
            ))}
            {this.renderFriendRequestModal()}
          </SideBarContent> :
          <SideBarContent><NoFriendRequests>No friend requests to show</NoFriendRequests></SideBarContent>
      }
    </TileContainer>
  );

  renderActivityFeeds = (feeds) => (
    <TileContainer>
      <TitleContent>
        <i className="fas fa-list" />
        <span>Activity Feed:</span>
        <span><span>{feeds.length}</span>&nbsp;new notifications</span>
      </TitleContent>
      {feeds.length > 0 ? feeds.map((feed, index) => (
        <SideBarContent key={index} notificationSection >
          <StyledImage left>
            <div className="middle">
              <Image image={feed.friend_image} alt={feed.friend_name} />
            </div>
            <NotificationDescription>
              {feed.friend_name} <span className=" lowerCase">{feed.action_type + ' with '}</span>
              {feed.fof_name}
            </NotificationDescription>
          </StyledImage>
        </SideBarContent>
      )) : <NoActivityFeeds>No activities to show</NoActivityFeeds>}
    </TileContainer>
  );

  onHide = () => {
    this.setState({
      showModal: '',
      imgSrc: []
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(this.props.profileImage != nextProps.profileImage) {
      this.checkBase64(nextProps.profileImage);
    }
  }

  checkBase64(profileImage) {
    if(profileImage) {
      let arr = profileImage.split('/');
      if(arr[0] !== 'profile-Image') {
        this.setState({
          isUploadedImage: true
        })
      }
      else {
        this.setState({
          isUploadedImage: false
        })
      }
    }
  }

  togglePostButton = (value, image) => {
    const { shoutoutText, userIdArray } = this.state;
    if(userIdArray.length !== 0) {
      if(userIdArray.length !== 0 && !_.isNull(shoutoutText)) {
        return false;
      } else
        return true;
    } else if(value && value.trim() !== '' || image !== ''){
      return false;
    }
    return true;
  };

  renderPostModal = () => {
    const { showModal, imgSrc, isUploadedImage, inspirationQuote } = this.state;
    const { profileImage } = this.props;
    return (
      <Modal show={showModal === 'postModal'} onHide={this.onHide}>
        <Modal.Header closeButton>
          <StyledTitle>UPLOAD PHOTO</StyledTitle>
        </Modal.Header>
        <Modal.Body>
          <HeaderContainer onModal>
            { !isUploadedImage ?
              <Image
                image={profileImage}
                alt="user"
              /> : <img src={profileImage} alt="user" />
            }
            {this.renderInputField("Say something about this photo...", '', 'isCommentOrReply')}
          </HeaderContainer>
          <ImageContainer>
            <img src={imgSrc} alt="uploadable" />
          </ImageContainer>
          <ButtonContainer>
            <CommentButton onClick={this.addPost} disabled={this.togglePostButton(inspirationQuote, profileImage)}>post</CommentButton>
          </ButtonContainer>
        </Modal.Body>
      </Modal>
    )
  };

  addPost = () => {
    const { imgSrc, mentionedUsers, users, userIdArray, inspirationQuote, taggedUser, exemplifyID } = this.state;
    const { addSocialInspiration, activeTab } = this.props;
    let inspirationData = {
      image: imgSrc.length >= 1 ?  imgSrc[0] : '',
      exemplify_id: exemplifyID,
      shoutout: {
        exemplifies: mentionedUsers
      },
      description: inspirationQuote
    };
    if(!_.isEmpty(mentionedUsers)) {
      inspirationData.shoutout_user_name = `${applyHighlights(users, userIdArray, taggedUser)} - ${this.state.shoutoutText}`;
    }
    this.onHide();
    addSocialInspiration(inspirationData);
    let active = activeTab === "Company" ? "Company" : "Everybody";
    this.props.setActiveTab(active);
    this.setState({
      inspirationQuote: '',
      imgSrc: [],
      showGivenShoutout: false,
      shoutoutImage: null,
      shoutoutText: null,
      taggedUser: '',
      userIdArray: [],
      users: [],
      mentionedUsers: [],
      shoutoutType: 0,
    })
  };

  renderAssessmentPanel = () => {
    const { history } = this.props;
    return(
      <AssessmentContainer>
        <TipInformation onClick={() => history.push('/wellness-assessment/medical')}>
          <HraIcon><img src={'/public/images/HRA_icon.svg'} /></HraIcon>
          <span>TAKE THE HEALTH RISK ASSESSMENT</span>
        </TipInformation>
      </AssessmentContainer>
    );
  };

  renderView = () => {
    const { inspirationQuote, userIdArray, shoutoutText } = this.state;
    const { feeds, activityFeeds, wellnessExpert, friendRequests, iswbaTaken, history, companyId, suggestionList } = this.props;
    return(
      <Layout>
        <PageTitle>Update Status</PageTitle>
        <ContentContainer>
          <SocialFeedsContainer>
            <CommentSection>
              {this.renderInputField("What's on your mind...",  "UpdateStatus")}
              {
                (userIdArray.length !== 0 ?
                  (userIdArray.length !== 0 && !_.isNull(shoutoutText)) ?
                    <CommentButton right onClick={this.addPost}>post</CommentButton> :
                    null :
                  inspirationQuote && inspirationQuote.trim() !== '' && <CommentButton right onClick={this.addPost}>post</CommentButton>)
              }
            </CommentSection>
            {this.renderFeeds(feeds)}
          </SocialFeedsContainer>
          <SideBarContainer>
            {iswbaTaken ? this.renderDidYouKnowTip() : this.renderAssessmentPanel()}
            {friendRequests.length > 0 && this.renderFriendRequests(friendRequests)}
            {this.renderActivityFeeds(activityFeeds)}
            {companyId /*=== 18*/ ? <WellnessExpertSection data={wellnessExpert} /> : null}
            <FeaturedEventsContainer>
              <FeaturedChallenges isSocialFeedsPage history={history} />
            </FeaturedEventsContainer>
            <SuggestionForYou suggestionList={suggestionList} history={history}/>
          </SideBarContainer>
        </ContentContainer>
      </Layout>
    );
  };

  handleSelect = (key) => {
    this.props.setActiveTab(key);
  };

  render() {
    const { showEditDeletePopup, editDetails } = this.state;
    const { profileImage, activeTab, enableTrigger, userPermissions } = this.props;

    if(!profileImage) {
      return <Waiting />
    }

    return (
      <div>
        <StyledTabContainer defaultActiveKey="Everybody" activeKey={activeTab} id="styledTabs" onSelect={this.handleSelect} bgColor={'none'} >
          <Tab eventKey="Everybody" title="All">
            {this.renderView()}
          </Tab>
          { (getPermissionStatus("Show company tab", userPermissions)) &&
          <Tab eventKey="Company" title="Company">
            {this.renderView()}
          </Tab>
          }
          <Tab eventKey="Wellness Experts" title="expert">
            {this.renderView()}
          </Tab>
        </StyledTabContainer>
        {this.renderPostModal()}
        <Suspense fallback={<Waiting/>}>
          <EditPostPopUp
            feed={editDetails}
            showModal={showEditDeletePopup == 'edit'}
            onClose={this.onHideEditDeletePopup}
            updationList="response"
            profileImage={this.props.profileImage}
            enableTrigger={enableTrigger}
          />
        </Suspense>
        <Suspense fallback={<Waiting/>}>
          <DeletePostPopUp
            feed={editDetails}
            showModal={showEditDeletePopup == 'delete'}
            onClose={this.onHideEditDeletePopup}
            updationList="response"
            title={this.props.t("DELETE POST")}
            subContent={this.props.t("This action cannot be undone.")}
          />
        </Suspense>
      </div>
    )
  }
}

SocialFeeds.propTypes = {
  feeds: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  addSocialInspiration: PropTypes.func.isRequired,
  friendRequests: PropTypes.array.isRequired,
  acceptOrRejectFriendRequest: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  activityFeeds: PropTypes.array.isRequired,
  comments: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  fetchCommentsOnPost: PropTypes.func.isRequired,
  postReplyOnComment: PropTypes.func.isRequired,
  replySuccess: PropTypes.string,
  loadMoreFeeds: PropTypes.func.isRequired,
  wellnessExpert: PropTypes.object,
  iswbaTaken: PropTypes.bool.isRequired,
  profileImage: PropTypes.string,
  fetchUserProfileImage: PropTypes.func,
  count: PropTypes.number,
  role: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  fetchUsersToShoutout: PropTypes.func,
  userCompany: PropTypes.object.isRequired,
  employeeData: PropTypes.array,
  enableTrigger: PropTypes.bool,
  userPermissions: PropTypes.array,
  companyId: PropTypes.number.isRequired,
  suggestionList: PropTypes.array,
  getSuggestionsList: PropTypes.func,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  isLoading: state.social.isLoading,
  comments: state.social.comments,
  replySuccess: state.social.replySuccess,
  profileImage: state.profileData.profileImage,
  count: state.social.count,
  employeeData: state.peopleHome.employeeData,
  userCompany: state.profileData.userCompany,
  userPermissions: state.profileData.userPermissions,
  suggestionList: state.social.suggestionList
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsersToShoutout: (data, cb) => dispatch(getUsersToShoutout(data, cb)),
  addSocialInspiration: (inspirationData) => dispatch(addSocialInspiration(inspirationData)),
  fetchCommentsOnPost: (id) => dispatch(getCommentsOnPost(id)),
  fetchUserProfileImage: () => dispatch(getUserProfileImage()),
  postReplyOnComment: (commentId, reply, postId, shoutout, shoutOutUserName, exemplify_id, image) => dispatch(addCommentReplyOnPost(commentId, reply, postId, shoutout, shoutOutUserName, exemplify_id, image)),
  getSuggestionsList: () => dispatch(getSuggestionsList())
});

export default connect(mapStateToProps, mapDispatchToProps)((withTranslation())(SocialFeeds));
